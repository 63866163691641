import { fetchJson } from '@/api/fetch';

import type { FeaturedPlaylist, Tile } from '@/types/carousel';
import type { NextIncomingMessage } from '@/types/request';

interface GetFeaturedPlaylistResponse {
  playlists: Tile[];
  success: boolean;
  styles: {
    category: {
      id: number;
      name: string;
      order: number;
    };
    results: {
      url: string;
      id: number;
      name: string;
      slug: string;
      tile_image: string;
      order: number;
    }[];
  }[];
}

export const getFeaturedPlaylist = async ({
  req,
}: { req?: NextIncomingMessage } = {}): Promise<FeaturedPlaylist> => {
  const data = await fetchJson<GetFeaturedPlaylistResponse>(
    '/track/styles/featuredPlaylist',
    { req },
  );

  const styles = data.styles
    .sort((a, b) => a.category.order - b.category.order)
    .map(({ category, results }) => ({
      category,
      tiles: results,
    }));

  return {
    creatorPicks: data.playlists,
    styles,
  };
};
