import { fetchJson } from '@/api/fetch';
import { getAuthToken } from '@/auth';

import type { Carousel } from '@/types/carousel';
import type { NextIncomingMessage } from '@/types/request';

type JumpBackInCarouselsResponse =
  | {
      name: string;
      displayName?: string;
      displayPicture?: string;
      image: string;
      url: string;
      description?: string;
      type: string;
    }[]
  | string;

export const getJumpBackInCarousels = async ({
  req,
}: { req?: NextIncomingMessage } = {}): Promise<Carousel[]> => {
  const token = getAuthToken(req);

  if (!token) {
    return [];
  }

  let data;

  try {
    data = await fetchJson<JumpBackInCarouselsResponse>(
      '/carousels/10/maxslide/recent/download',
      { token, req },
    );
  } catch (err) {
    console.error(err);

    return [];
  }

  if (!Array.isArray(data)) {
    return [];
  }

  return [
    {
      name: 'Jump back in',
      order: 1,
      tiles: data.map((item, index) => ({
        id: index,
        name: item.name,
        order: index,
        slug: '',
        tile_image: item.image,
        url: item.url,
        type: item.type,
        description: item.description,
        ...(item.displayName &&
          item.displayPicture && {
            playlistOwnDisplayName: item.displayName,
            profile_picture: item.displayPicture,
          }),
      })),
    },
  ];
};
