import { fetchJson } from '@/api/fetch';
import { getAuthToken } from '@/auth';

import type { RecentDownloadsCarousel } from '@/types/carousel';
import type { NextIncomingMessage } from '@/types/request';

interface GetRecentDownloadsCarouselsResponse {
  success: boolean;
  message?: string;
  data: {
    name?: string;
    trackName: string;
    trackSlug: string;
    trackEnergy: number;
    artistName: string;
    artistSlug: string;
    imageAwsUrl: string;
    trackId: number;
    similarArtist1Name: string;
    similarArtist1Image: string;
    similarArtist2Name: string;
    similarArtist2Image: string;
  }[];
}

export const getRecentDownloadsCarousels = async ({
  req,
}: { req?: NextIncomingMessage } = {}): Promise<RecentDownloadsCarousel[]> => {
  const token = getAuthToken(req);

  if (!token) {
    return [];
  }

  let data;

  try {
    data = await fetchJson<GetRecentDownloadsCarouselsResponse>(
      '/carousels/10/maxslide/recent/download/artist/cache',
      { token, req },
    );
  } catch (err) {
    console.error(err);

    return [];
  }

  if (!Array.isArray(data.data)) {
    return [];
  }

  return [
    {
      name: 'Based on your downloads',
      order: 1,
      tiles: data.data.map((item, index) => ({
        id: index,
        name: item.name,
        order: index,
        slug: '',
        tile_image_main: item.imageAwsUrl,
        tile_image_left: item.similarArtist1Image,
        tile_image_right: item.similarArtist2Image,
        main_track_artist: item.artistName,
        main_track_name: item.trackName,
        main_track_energy: item.trackEnergy,
        main_track_id: item.trackId,
        url: `/similar/${item.artistSlug}/${item.trackSlug}`,
      })),
    },
  ];
};
