import { clsx } from 'clsx';

import styles from './styles.module.css';

import { Slider } from '.';

interface PlaceholderSliderCarouselProps {
  numberOfSlides?: number;
}

export const SliderSkeleton = ({
  numberOfSlides = 10,
}: PlaceholderSliderCarouselProps) => {
  return (
    <div
      className={clsx(styles.mainCarouselWrapper, styles.placeholderCarousel)}
    >
      <div className={styles.mainCarouselWrapperItem}>
        <span
          className={clsx(
            styles.mainCarouselWrapperTitle,
            styles.placeholder,
            'placeholder',
            'ub-skeleton',
            'ub-skeleton-dark',
          )}
        />
        <Slider>
          {Array(numberOfSlides)
            .fill(null)
            .map((_, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={i}>
                <div
                  className={clsx(
                    styles.mainCarouselWrapperTileImage,
                    'placeholder',
                    'ub-skeleton',
                    'ub-skeleton-dark',
                  )}
                />
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};
