import { captureException } from '@sentry/nextjs';

import { getFeaturedPlaylist } from '@/api/playlists/get-featured-playlist';
import { playlistTiles } from '@/fixtures/playlistTiles';
import { styleCarousels } from '@/fixtures/styleCarousels';

import { getDiscoverCarousels } from './get-discover-carousels';
import { getJumpBackInCarousels } from './get-jump-back-in-carousels';
import { getRecentDownloadsCarousels } from './get-recent-downloads-carousels';

import type { FeaturedPlaylist, StyleCarousel } from '@/types/carousel';
import type { NextIncomingMessage } from '@/types/request';

const getFeaturedCarousels = async () => {
  let featuredCarousels: StyleCarousel[];

  try {
    const featured: FeaturedPlaylist | undefined = await getFeaturedPlaylist();

    featuredCarousels = featured?.styles ?? styleCarousels;

    featuredCarousels.splice(2, 0, {
      category: {
        id: 3,
        order: 1,
        name: 'Top creator picks',
      },
      tiles: featured?.creatorPicks ?? playlistTiles,
    });
  } catch (err) {
    captureException(err);
    featuredCarousels = [];
  }

  return featuredCarousels;
};

export const getHomepageCarousels = async ({
  req,
}: { req?: NextIncomingMessage } = {}) => {
  const [
    featuredCarousels,
    discoverCarousels,
    basedOnRecentDlCarousels,
    jumpBackInCarousels,
  ] = await Promise.all([
    getFeaturedCarousels(),
    getDiscoverCarousels(),
    getRecentDownloadsCarousels({ req }),
    getJumpBackInCarousels({ req }),
  ]);

  return {
    homepage: featuredCarousels,
    discover: discoverCarousels,
    basedOnRecentDl: basedOnRecentDlCarousels,
    jumpBackIn: jumpBackInCarousels,
  };
};
