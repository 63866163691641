import type { Tile } from '@/types/carousel';

export const playlistTiles: Tile[] = [
  {
    id: 1,
    order: 1,
    slug: '',
    name: 'Elevate & Engage',
    tile_image:
      'https://cdn.uppbeat.io/images/playlist/UB_Playlists_Partners_Devin-Graham_Elevate-and-Engage.jpg',
    url: '/playlist/elevate-engage',
    profile_picture:
      'https://cdn.uppbeat.io/images/playlist/Partner-Thumbnail_Devin-Graham.jpg',
    playlistOwnDisplayName: 'DevinSuperTramp',
  },
  {
    id: 2,
    order: 2,
    slug: '',
    name: 'Breezy Vibes',
    tile_image:
      'https://cdn.uppbeat.io/images/playlist/UB_Playlists_Partners_Pick-Up-Limes_Breezy-Vibes.jpg',
    url: '/playlist/breezy-vibes',
    profile_picture:
      'https://cdn.uppbeat.io/images/playlist/Partner-Thumbnail_Pick-Up-Limes.jpg',
    playlistOwnDisplayName: 'Pick Up Limes',
  },
  {
    id: 3,
    order: 3,
    slug: '',
    name: 'Mellow Waters',
    tile_image:
      'https://cdn.uppbeat.io/images/playlist/UB_Playlists_Partners_Simon-Cade_Mellow-Waters.jpg',
    url: '/playlist/mellow-waters',
    profile_picture:
      'https://cdn.uppbeat.io/images/playlist/Partner-Thumbnail_Simon-Cade.jpg',
    playlistOwnDisplayName: 'DSLRguide',
  },
  {
    id: 4,
    order: 4,
    slug: '',
    name: 'Cosy Vibes',
    tile_image:
      'https://cdn.uppbeat.io/images/playlist/UB_Playlists_Partners_Lizzie-Pierce-Cozy-Vibes.jpg',
    url: '/playlist/cosy-vibes',
    profile_picture:
      'https://cdn.uppbeat.io/images/playlist/Partner-Thumbnail_Lizzie-Peirce.jpg',
    playlistOwnDisplayName: 'Lizzie Peirce',
  },
  {
    id: 5,
    order: 5,
    slug: '',
    name: 'Minimal Beats',
    tile_image:
      'https://cdn.uppbeat.io/images/playlist/UB_Playlists_Partners_Oliur-Rahman_Minimal-Beats.jpg',
    url: '/playlist/minimal-beats',
    profile_picture:
      'https://cdn.uppbeat.io/images/playlist/Partner-Thumbnail_Oliur-Rahman.jpg',
    playlistOwnDisplayName: 'Oliur',
  },
  {
    id: 6,
    order: 6,
    slug: '',
    name: 'Vlogging In Style',
    tile_image:
      'https://cdn.uppbeat.io/images/playlist/UB_Playlists_Partners_Sidney-Diongzon_Vlogging-in-Style.jpg',
    url: '/playlist/vlogging-in-style',
    profile_picture:
      'https://cdn.uppbeat.io/images/playlist/Partner-Thumbnail_Sidney-Diongzon.jpg',
    playlistOwnDisplayName: 'Sidney Diongzon',
  },
  {
    id: 7,
    order: 7,
    slug: '',
    name: 'Creative Flow',
    tile_image:
      'https://cdn.uppbeat.io/images/playlist/UB_Playlists_Partners_Tristan-Barrocks_Creative-Flow.jpg',
    url: '/playlist/creative-flow',
    profile_picture:
      'https://cdn.uppbeat.io/images/playlist/Partner-Thumbnail_Tristan-Barrocks.jpg',
    playlistOwnDisplayName: 'Tristan Barrocks',
  },
];
