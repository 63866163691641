import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useQuery } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo } from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';

import { SEO } from '@/components/SEO';
import SliderCarousel from '@/components/Slider/SliderCarousel';
import { SliderSkeleton } from '@/components/Slider/SliderSkeleton';
import { T } from '@/components/T';
import UBCategories from '@/components/UBCategories';
import { NO_BREAK_SPACE } from '@/constants/space';
import { musicTagCategories } from '@/fixtures/musicTagCategories';
import { useAppState } from '@/hooks/useAppState';
import { useCurrency } from '@/hooks/useCurrency';
import { useTranslate } from '@/hooks/useTranslate';
import { useUserPlanData } from '@/hooks/useUserPlanData';
import { SettingsContext } from '@/providers/SettingsProvider';
import { TNamespaceProvider } from '@/providers/TNamespaceProvider';
import { UserStateContext } from '@/providers/UserStateProvider';
import { parseEmoji } from '@/utils/helpers';

import {
  getMusicPageCacheKey,
  getMusicPageData,
  getMusicPageHeadings,
  isPopulatedCarousel,
} from './util';

import type { Greeting } from './types';
import type {
  Carousel,
  RecentDownloadsCarousel,
  StyleCarousel,
} from '@/types/carousel';
import type { Organization } from 'schema-dts';

interface HomePageProps {
  initialCacheKey?: (string | { [x: string]: boolean | string })[];
  initialData?: {
    carousels: {
      homepage?: StyleCarousel[];
      discover?: Carousel[];
      basedOnRecentDl?: RecentDownloadsCarousel[];
      jumpBackIn?: Carousel[];
    };
  };
  initialHeadingsData?: {
    heading: Greeting;
    subheading: string;
  } | null;
}

export const MusicPage: React.FC<HomePageProps> = ({
  initialCacheKey,
  initialData,
  initialHeadingsData,
}) => {
  const currency = useCurrency();
  const userAuth = useAppState('userAuth');
  const { settings } = useContext(SettingsContext);
  const { userState } = useContext(UserStateContext);
  const cacheKey = getMusicPageCacheKey(settings);
  const { t } = useTranslate('common');
  const { locale } = useRouter();

  const { data } = useQuery({
    queryKey: cacheKey,
    queryFn: () => getMusicPageData(),
    initialData,
    enabled: !isEqual(cacheKey, initialCacheKey),
  });

  const { data: headingsData } = useQuery({
    queryKey: [settings?.user?.first_name, 'homepage', 'headings'],
    queryFn: () => getMusicPageHeadings(settings),
    staleTime: Infinity, // Maintain the same headings for a session
  });

  const isHideAipgTrendingOn = useFeatureIsOn('hide-aipg-trending');

  const currentHeadingData = useMemo(() => {
    const newHeading = headingsData ?? initialHeadingsData;

    return {
      ...newHeading,
      heading: {
        default: newHeading?.heading?.default
          .replace('{name}', settings?.user?.first_name ?? '')
          .replace('{greeting}', newHeading.heading.timeGreeting ?? ''),
      },
    };
  }, [headingsData, initialHeadingsData, settings?.user?.first_name]);

  const userPlanData = useUserPlanData();
  const currencySymbol = currency?.symbol_left;

  useEffect(() => {
    // parseEmoji ends up in a race with Toglee
    if (locale?.startsWith('en')) {
      parseEmoji();
    }
  }, [locale]);

  return (
    <TNamespaceProvider value="homepage">
      <section className="homepage">
        <div className="page-container home-container">
          <SEO
            title={`${t(
              'meta.title',
              'Free Music For YouTube Videos & Creators',
            )} • Uppbeat`}
            description={t(
              'meta.description',
              'Download the best copyright-free music for YouTube, TikTok, streaming, social media, podcasts and more. Create a free account & start downloading for free now.',
            )}
          >
            <script
              {...jsonLdScriptProps<Organization>({
                '@context': 'https://schema.org',
                '@type': 'Organization',
                name: 'Uppbeat',
                url: 'https://uppbeat.io/',
                logo: 'https://cdn.uppbeat.io/images/schema-logo.png',
                parentOrganization: {
                  '@type': 'Organization',
                  name: 'Music Vine Limited',
                },
                address: {
                  '@type': 'PostalAddress',
                  streetAddress: 'Tailors Corner, 1 Thirsk Row',
                  addressLocality: 'Leeds',
                  postalCode: 'LS1 4DP',
                  addressCountry: 'UK',
                },
                description: t(
                  'schema.description',
                  'Uppbeat is a pioneering free music platform for YouTubers and content creators that guarantees no YouTube copyright issues or demonetization of content. Headquartered in Leeds, UK, and owned by Music Vine Limited, Uppbeat launched in 2021 with a curated catalog of top beatmakers and indie artists. The platform now also offers an extensive library of sound effects as well as a leading blog with valuable tips and information for its userbase of over 1.5 million creators.',
                ),
                foundingDate: '2021-01-18',
                sameAs: [
                  'https://www.instagram.com/UppbeatOfficial/',
                  'https://www.facebook.com/UppbeatOfficial/',
                  'https://twitter.com/UppbeatOfficial',
                  'https://discord.gg/q5xaCqhGFC',
                ],
              })}
            />
          </SEO>
          <div className="ub-heading ub-heading-home">
            <h1>{currentHeadingData?.heading.default}</h1>
            <h2>
              {!!currentHeadingData?.subheading && (
                <T keyName={currentHeadingData?.subheading} ns="common">
                  {currentHeadingData?.subheading ?? NO_BREAK_SPACE}
                </T>
              )}
            </h2>
            {userPlanData?.monthly &&
              userAuth?.isLoggedIn &&
              !userAuth?.isPremium && (
                <h3>
                  Upgrade for only {currencySymbol}
                  {userPlanData.monthlyPrice} p/month to unlock everything.{' '}
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="#">Learn more</a>
                </h3>
              )}
          </div>
          <div className="home-page_carousel-listing">
            {!data?.carousels ? (
              <>
                <SliderSkeleton />
                <SliderSkeleton />
                <SliderSkeleton />
              </>
            ) : (
              <>
                {data.carousels.discover?.map(
                  (carousel) =>
                    isPopulatedCarousel(carousel) && (
                      <SliderCarousel
                        key={carousel.name}
                        title={carousel.name}
                        data={
                          isHideAipgTrendingOn && userAuth?.isLoggedIn
                            ? carousel.tiles.slice(2)
                            : carousel.tiles
                        }
                      />
                    ),
                )}
                {data.carousels.jumpBackIn?.map(
                  (carousel) =>
                    isPopulatedCarousel(carousel) && (
                      <SliderCarousel
                        key={carousel.name}
                        title={carousel.name}
                        data={carousel.tiles}
                      />
                    ),
                )}

                {data.carousels.basedOnRecentDl?.map(
                  (carousel) =>
                    isPopulatedCarousel(carousel, 2) && (
                      <SliderCarousel
                        key={carousel.name}
                        title={carousel.name}
                        data={carousel.tiles}
                      />
                    ),
                )}
                {data.carousels.homepage?.map(
                  (carousel) =>
                    isPopulatedCarousel(carousel) && (
                      <SliderCarousel
                        key={carousel.category.name}
                        title={carousel.category.name}
                        data={carousel.tiles}
                      />
                    ),
                )}
              </>
            )}
          </div>
          <UBCategories
            heading="All Categories"
            categories={musicTagCategories}
            type="music"
          />
        </div>
      </section>
    </TNamespaceProvider>
  );
};
