import type { StyleCarousel } from '@/types/carousel';

export const styleCarousels: StyleCarousel[] = [
  {
    category: {
      id: 1,
      name: 'Beats',
      order: 1,
    },
    tiles: [
      {
        url: '/browse/music/sunny-beats',
        id: 1,
        name: 'Sunny Beats',
        slug: 'sunny-beats',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Sunny-Beats.jpg',
        order: 1,
      },
      {
        url: '/browse/music/vintage-beats',
        id: 12,
        name: 'Vintage Beats',
        slug: 'vintage-beats',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Vintage-Beats.jpg',
        order: 2,
      },
      {
        url: '/browse/music/stylish-beats',
        id: 4,
        name: 'Stylish Beats',
        slug: 'stylish-beats',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Stylish-Beats.jpg',
        order: 3,
      },
      {
        url: '/browse/music/chillhop',
        id: 9,
        name: 'Chillhop',
        slug: 'chillhop',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Chillhop-Beats.jpg',
        order: 4,
      },
      {
        url: '/browse/music/hip-hop-beats',
        id: 15,
        name: 'Hip-Hop Beats',
        slug: 'hip-hop-beats',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Hip-Hop-Beats.jpg',
        order: 5,
      },
      {
        url: '/browse/music/lazin-beats',
        id: 2,
        name: "Lazin' Beats",
        slug: 'lazin-beats',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Lazin-Beats.jpg',
        order: 6,
      },
      {
        url: '/browse/music/nyc-vibe',
        id: 5,
        name: 'NYC Vibe',
        slug: 'nyc-vibe',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_NYC-Vibe.jpg',
        order: 7,
      },
      {
        url: '/browse/music/lo-fi-beats',
        id: 7,
        name: 'Lo-Fi Beats',
        slug: 'lo-fi-beats',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Lo-fi-Beats.jpg',
        order: 8,
      },
      {
        url: '/browse/music/lush-beats',
        id: 3,
        name: 'Lush Beats',
        slug: 'lush-beats',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Lush-Beats.jpg',
        order: 9,
      },
      {
        url: '/browse/music/ambient-beats',
        id: 14,
        name: 'Ambient Beats',
        slug: 'ambient-beats',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Ambient-Beats.jpg',
        order: 10,
      },
      {
        url: '/browse/music/majestic-beats',
        id: 6,
        name: 'Majestic Beats',
        slug: 'majestic-beats',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Majestic-Beats.jpg',
        order: 11,
      },
      {
        url: '/browse/music/jazzy-beats',
        id: 13,
        name: 'Jazzy Beats',
        slug: 'jazzy-beats',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Jazzy-Beats.jpg',
        order: 12,
      },
      {
        url: '/browse/music/trap',
        id: 11,
        name: 'Trap',
        slug: 'trap',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Trap.jpg',
        order: 13,
      },
      {
        url: '/browse/music/future-bass',
        id: 8,
        name: 'Future Bass',
        slug: 'future-bass',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Future-Bass.jpg',
        order: 14,
      },
      {
        url: '/browse/music/underground-beats',
        id: 10,
        name: 'Underground Beats',
        slug: 'underground-beats',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Underground-Beats.jpg',
        order: 15,
      },
      {
        url: '/browse/music/late-night-beats',
        id: 16,
        name: 'Late Night Beats',
        slug: 'late-night-beats',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Beats_Late-Night-Beats.jpg',
        order: 16,
      },
    ],
  },
  {
    category: {
      id: 2,
      name: 'Background Vibe',
      order: 2,
    },
    tiles: [
      {
        url: '/browse/music/lighthearted',
        id: 71,
        name: 'Lighthearted',
        slug: 'lighthearted',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Background-Vibes_Lighthearted.jpg',
        order: 1,
      },
      {
        url: '/browse/music/understated',
        id: 67,
        name: 'Understated',
        slug: 'understated',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Background-Vibes_Understated.jpg',
        order: 2,
      },
      {
        url: '/browse/music/easygoing',
        id: 74,
        name: 'Easygoing',
        slug: 'easygoing',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Background-Vibes_Easygoing.jpg',
        order: 3,
      },
      {
        url: '/browse/music/modern-background',
        id: 73,
        name: 'Modern Background',
        slug: 'modern-background',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Background-Vibes_Modern-Background.jpg',
        order: 4,
      },
      {
        url: '/browse/music/good-vibes',
        id: 68,
        name: 'Good Vibes',
        slug: 'good-vibes',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Background-Vibes_Good-Vibes.jpg',
        order: 5,
      },
      {
        url: '/browse/music/wholesome',
        id: 69,
        name: 'Wholesome',
        slug: 'wholesome',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Background-Vibes_Wholesome.jpg',
        order: 6,
      },
      {
        url: '/browse/music/hype',
        id: 72,
        name: 'Hype',
        slug: 'hype',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Background-Vibes_Hype.jpg',
        order: 7,
      },
      {
        url: '/browse/music/gloss-glam',
        id: 70,
        name: 'Gloss & Glam',
        slug: 'gloss-glam',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Background-Vibes_Gloss-and-Glam.jpg',
        order: 8,
      },
    ],
  },
  {
    category: {
      id: 3,
      name: 'Mood',
      order: 4,
    },
    tiles: [
      {
        url: '/browse/music/inspiring',
        id: 20,
        name: 'Inspiring',
        slug: 'inspiring',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Mood_Inspiring.jpg',
        order: 1,
      },
      {
        url: '/browse/music/energizing',
        id: 19,
        name: 'Energizing',
        slug: 'energizing',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Mood_Energizing.jpg',
        order: 2,
      },
      {
        url: '/browse/music/calm',
        id: 22,
        name: 'Calm',
        slug: 'calm',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Mood_Calm.jpg',
        order: 3,
      },
      {
        url: '/browse/music/happy',
        id: 17,
        name: 'Happy',
        slug: 'happy',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Mood_Happy.jpg',
        order: 4,
      },
      {
        url: '/browse/music/dramatic',
        id: 25,
        name: 'Dramatic',
        slug: 'dramatic',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Mood_Dramatic.jpg',
        order: 5,
      },
      {
        url: '/browse/music/uplifting',
        id: 23,
        name: 'Uplifting',
        slug: 'uplifting',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Mood_Uplifting.jpg',
        order: 6,
      },
      {
        url: '/browse/music/atmospheric',
        id: 21,
        name: 'Atmospheric',
        slug: 'atmospheric',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Mood_Atmospheric.jpg',
        order: 7,
      },
      {
        url: '/browse/music/aggressive',
        id: 28,
        name: 'Aggressive',
        slug: 'aggressive',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Mood_Aggressive.jpg',
        order: 8,
      },
      {
        url: '/browse/music/sentimental',
        id: 24,
        name: 'Sentimental',
        slug: 'sentimental',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Mood_Sentimental.jpg',
        order: 9,
      },
      {
        url: '/browse/music/dark',
        id: 26,
        name: 'Dark',
        slug: 'dark',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Mood_Dark.jpg',
        order: 10,
      },
      {
        url: '/browse/music/sad',
        id: 18,
        name: 'Sad',
        slug: 'sad',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Mood_Sad.jpg',
        order: 11,
      },
      {
        url: '/browse/music/scary',
        id: 27,
        name: 'Scary',
        slug: 'scary',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Mood_Scary.jpg',
        order: 12,
      },
    ],
  },
  {
    category: {
      id: 4,
      name: 'Funny',
      order: 5,
    },
    tiles: [
      {
        url: '/browse/music/silly-energy',
        id: 61,
        name: 'Silly Energy',
        slug: 'silly-energy',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Funny_Silly-Energy.jpg',
        order: 1,
      },
      {
        url: '/browse/music/gentlemans-jukebox',
        id: 58,
        name: "Gentleman's Jukebox",
        slug: 'gentlemans-jukebox',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Funny_Gentlemans-Jukebox.jpg',
        order: 2,
      },
      {
        url: '/browse/music/ironic-dramatic',
        id: 63,
        name: 'Ironic Dramatic',
        slug: 'ironic-dramatic',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Funny_Ironic-Dramatic.jpg',
        order: 3,
      },
      {
        url: '/browse/music/quirky',
        id: 65,
        name: 'Quirky',
        slug: 'quirky',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Funny_Quirky.jpg',
        order: 4,
      },
      {
        url: '/browse/music/dramedy',
        id: 66,
        name: 'Dramedy',
        slug: 'dramedy',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Funny_Dramedy.jpg',
        order: 5,
      },
      {
        url: '/browse/music/up-to-mischief',
        id: 60,
        name: 'Up To Mischief',
        slug: 'up-to-mischief',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Funny_Up-to-mischief.jpg',
        order: 6,
      },
      {
        url: '/browse/music/cuteness',
        id: 59,
        name: 'Cuteness',
        slug: 'cuteness',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Funny_Cuteness.jpg',
        order: 7,
      },
      {
        url: '/browse/music/ironic-sad',
        id: 62,
        name: 'Ironic Sad',
        slug: 'ironic-sad',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Funny_Ironic-Sad.jpg',
        order: 8,
      },
      {
        url: '/browse/music/retro-parody',
        id: 64,
        name: 'Retro Parody',
        slug: 'retro-parody',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Funny_Retro-Parody.jpg',
        order: 9,
      },
    ],
  },
  {
    category: {
      id: 5,
      name: 'Creative Toolkit',
      order: 6,
    },
    tiles: [
      {
        url: '/browse/music/trippy',
        id: 57,
        name: 'Trippy',
        slug: 'trippy',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Creative-Toolkit_Trippy.jpg',
        order: 1,
      },
      {
        url: '/browse/music/fast-cut',
        id: 51,
        name: 'Fast-Cut',
        slug: 'fast-cut',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Creative-Toolkit_Fast-Cut.jpg',
        order: 2,
      },
      {
        url: '/browse/music/timelapse',
        id: 50,
        name: 'Timelapse',
        slug: 'timelapse',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Creative-Time-Lapse.jpg',
        order: 3,
      },
      {
        url: '/browse/music/aerial',
        id: 53,
        name: 'Aerial',
        slug: 'aerial',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Creative-Toolkit_Aerial.jpg',
        order: 4,
      },
      {
        url: '/browse/music/slow-mo',
        id: 54,
        name: 'Slow-Mo',
        slug: 'slow-mo',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Creative-Toolkit_Slow-Mo.jpg',
        order: 5,
      },
      {
        url: '/browse/music/montage',
        id: 56,
        name: 'Montage',
        slug: 'montage',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Creative-Toolkit_Montage.jpg',
        order: 6,
      },
      {
        url: '/browse/music/bokeh',
        id: 52,
        name: 'Bokeh',
        slug: 'bokeh',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Creative-Toolkit_Bokeh.jpg',
        order: 7,
      },
      {
        url: '/browse/music/stop-motion',
        id: 55,
        name: 'Stop Motion',
        slug: 'stop-motion',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Creative-Toolkit_Stop-Motion.jpg',
        order: 8,
      },
    ],
  },
  {
    category: {
      id: 6,
      name: 'Cinematic',
      order: 7,
    },
    tiles: [
      {
        url: '/browse/music/epic',
        id: 78,
        name: 'Epic',
        slug: 'epic',
        tile_image: 'https://cdn.uppbeat.io/images/UppBeat_Playlists_Epic.jpg',
        order: 1,
      },
      {
        url: '/browse/music/cinematic-soundbeds',
        id: 77,
        name: 'Cinematic Soundbeds',
        slug: 'cinematic-soundbeds',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Cinematic_Cinematic-Soundbeds.jpg',
        order: 2,
      },
      {
        url: '/browse/music/empowering',
        id: 79,
        name: 'Empowering',
        slug: 'empowering',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Empowering.jpg',
        order: 3,
      },
      {
        url: '/browse/music/magnificent',
        id: 75,
        name: 'Magnificent',
        slug: 'magnificent',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Magnificient.jpg',
        order: 4,
      },
      {
        url: '/browse/music/profound',
        id: 76,
        name: 'Profound',
        slug: 'profound',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat-Playlists_Cinematic_Profound.jpg',
        order: 5,
      },
    ],
  },
  {
    category: {
      id: 7,
      name: 'Theme',
      order: 8,
    },
    tiles: [
      {
        url: '/browse/music/vlog',
        id: 35,
        name: 'Vlog',
        slug: 'vlog',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Theme_Vlog.jpg',
        order: 1,
      },
      {
        url: '/browse/music/travel',
        id: 39,
        name: 'Travel',
        slug: 'travel',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Theme_Travel.jpg',
        order: 2,
      },
      {
        url: '/browse/music/health-and-fitness',
        id: 30,
        name: 'Health & Fitness',
        slug: 'health-and-fitness',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Theme_Health-Fitness.jpg',
        order: 3,
      },
      {
        url: '/browse/music/technology',
        id: 37,
        name: 'Technology',
        slug: 'technology',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Theme_Technology.jpg',
        order: 4,
      },
      {
        url: '/browse/music/gaming',
        id: 29,
        name: 'Gaming',
        slug: 'gaming',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Theme_Gaming.jpg',
        order: 5,
      },
      {
        url: '/browse/music/how-to-and-tutorial',
        id: 31,
        name: 'How-To & Tutorial',
        slug: 'how-to-and-tutorial',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Theme_How-To-Tutorial.jpg',
        order: 6,
      },
      {
        url: '/browse/music/spirit-and-wellbeing',
        id: 36,
        name: 'Spirit & Wellbeing',
        slug: 'spirit-and-wellbeing',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Theme_Spirit_and_Wellbeing.jpg',
        order: 7,
      },
      {
        url: '/browse/music/product-review',
        id: 32,
        name: 'Product Review',
        slug: 'product-review',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Theme_Product-Reviews.jpg',
        order: 8,
      },
      {
        url: '/browse/music/business',
        id: 38,
        name: 'Business',
        slug: 'business',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Theme_Business.jpg',
        order: 9,
      },
      {
        url: '/browse/music/kids',
        id: 34,
        name: 'Kids',
        slug: 'kids',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Theme_Kids.jpg',
        order: 10,
      },
      {
        url: '/browse/music/commentary',
        id: 33,
        name: 'Commentary',
        slug: 'commentary',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Theme_Commentary.jpg',
        order: 11,
      },
    ],
  },
  {
    category: {
      id: 8,
      name: 'Music Style',
      order: 9,
    },
    tiles: [
      {
        url: '/browse/music/electronic',
        id: 42,
        name: 'Electronic',
        slug: 'electronic',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Music-Style_Electronic.jpg',
        order: 1,
      },
      {
        url: '/browse/music/rocky',
        id: 41,
        name: 'Rocky',
        slug: 'rocky',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Music-Style_Rocky.jpg',
        order: 2,
      },
      {
        url: '/browse/music/acoustic',
        id: 40,
        name: 'Acoustic',
        slug: 'acoustic',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Music-Style_Acoustic.jpg',
        order: 3,
      },
      {
        url: '/browse/music/pop',
        id: 80,
        name: 'Pop',
        slug: 'pop',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Music-Style_Pop.jpg',
        order: 4,
      },
      {
        url: '/browse/music/ambient',
        id: 49,
        name: 'Ambient',
        slug: 'ambient',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Music-Style_Ambient.jpg',
        order: 5,
      },
      {
        url: '/browse/music/jazzy',
        id: 47,
        name: 'Jazzy',
        slug: 'jazzy',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Music-Style_Jazzy.jpg',
        order: 6,
      },
      {
        url: '/browse/music/percussive',
        id: 44,
        name: 'Percussive',
        slug: 'percussive',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Music-Style_Percussive.jpg',
        order: 7,
      },
      {
        url: '/browse/music/funky',
        id: 46,
        name: 'Funky',
        slug: 'funky',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Music-Style_Funky.jpg',
        order: 8,
      },
      {
        url: '/browse/music/indie',
        id: 43,
        name: 'Indie',
        slug: 'indie',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Music-Style_Indie.jpg',
        order: 9,
      },
      {
        url: '/browse/music/classical',
        id: 45,
        name: 'Classical',
        slug: 'classical',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Music-Style_Classical.jpg',
        order: 10,
      },
      {
        url: '/browse/music/americana',
        id: 48,
        name: 'Americana',
        slug: 'americana',
        tile_image:
          'https://cdn.uppbeat.io/images/UppBeat_Playlists_Music-Style_Americana.jpg',
        order: 11,
      },
    ],
  },
];
