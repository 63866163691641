import { fetchJson } from '@/api/fetch';

import type { Carousel } from '@/types/carousel';
import type { NextIncomingMessage } from '@/types/request';

type DiscoverCarouselsResponse = {
  name: string;
  order: number;
  tiles: {
    id: string;
    sort: number;
    discover_carousel: {
      id: number;
      name: string;
      target_url: string;
      image: {
        image_aws_url: string;
      };
      description?: string;
      short_description?: string;
    };
  }[];
}[];

export const getDiscoverCarousels = async ({
  req,
}: { req?: NextIncomingMessage } = {}): Promise<Carousel[]> => {
  let data;

  try {
    data = await fetchJson<DiscoverCarouselsResponse>(
      '/page/carousels/discover',
      {
        req,
      },
    );
  } catch (err) {
    console.error(err);

    return [];
  }

  return data.map((carousel) => ({
    ...carousel,
    tiles: carousel.tiles.map((tile, index) => ({
      id: tile.discover_carousel.id,
      name: tile.discover_carousel.name,
      order: tile.sort ?? index,
      slug: '',
      tile_image: tile.discover_carousel.image?.image_aws_url,
      url: tile.discover_carousel.target_url,
      ...(tile.discover_carousel.description && {
        description: tile.discover_carousel.description,
      }),
      ...(tile.discover_carousel.short_description && {
        short_description: tile.discover_carousel.short_description,
      }),
    })),
  }));
};
