import { useContext } from 'react';

import { SettingsContext } from '@/providers/SettingsProvider';

export const useUserPlanData = () => {
  const { settings } = useContext(SettingsContext);
  const userPlanData = settings?.plan_data;

  const currency = settings?.currency.code ?? 'GBP';
  const monthlyPrice = userPlanData?.monthly?.[currency] ?? 0;
  const yearlyPrice = userPlanData?.yearly?.[currency] ?? 0;
  const yearlyMonthlyPrice = userPlanData?.monthly_equivalent?.[currency] ?? 0;

  return {
    ...settings?.plan_data,
    currency,
    monthlyPrice,
    yearlyPrice,
    yearlyMonthlyPrice,
  };
};
