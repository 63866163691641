import { useFeatureIsOn } from '@growthbook/growthbook-react';
import Head from 'next/head';

import { useTranslate } from '@/hooks/useTranslate';

import type { ReactNode } from 'react';

interface SEOProps {
  title?: string;
  description?: string;
  children?: ReactNode;
}
export const defaultMetaTitle = 'Free Music For Creators';

export const defaultMetaDescription =
  "Create your free Uppbeat account & start downloading the best copyright-free music from the world's hottest beatmakers.";

export const SEO: React.FC<SEOProps> = ({ title, description, children }) => {
  const shouldShowSeoDebugger = useFeatureIsOn('seo-debugger');
  const { t } = useTranslate('seo');

  const metaTitle = title ?? t(defaultMetaTitle);

  const metaDescription = description ?? t(defaultMetaDescription);

  return (
    <>
      <Head>
        <title key="title">{metaTitle}</title>
        <meta key="description" name="description" content={metaDescription} />

        <meta key="og:title" property="og:title" content={metaTitle} />
        <meta
          key="og:description"
          property="og:description"
          content={metaDescription}
        />
        <meta key="twitter:title" name="twitter:title" content={metaTitle} />
        <meta
          key="twitter:description"
          name="twitter:description"
          content={metaDescription}
        />
        {children}
      </Head>
      {shouldShowSeoDebugger && (
        <div className="inline-block p-4">
          <div className="rounded-lg p-6 bg-gray-light-200 dark:bg-gray-dark-300 text-black dark:text-white border-2 border-dashed border-uppbeat">
            <div className="text-lg sm:text-xl font-bold">SEO Debugger</div>
            <hr className="my-4 opacity-30" />
            <dl className="sm:grid grid-cols-[auto_1fr] gap-x-4 gap-y-1 [&>dt]:font-semibold">
              <dt>TITLE</dt>
              <dd>{metaTitle}</dd>
              <dt>DESCRIPTION</dt>
              <dd>{metaDescription}</dd>
            </dl>
          </div>
        </div>
      )}
    </>
  );
};
